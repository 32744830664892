import React from 'react'

import Icon15xSvg from 'components/pages/m-series/img/icon-15x'
import IconMsSvg from 'components/pages/m-series/img/icon-ms'
import IconExodusSvg from 'components/pages/m-series/img/icon-exodus'

import 'components/pages/m-series/scss/features-section.scss'

const iconSVG = (title) => [
  <Icon15xSvg key={0} alt={title} />,
  <IconMsSvg key={1} alt={title} />,
  <IconExodusSvg key={2} alt={title} />,
]

export default function FeaturesSection({ data }) {
  return (
    <section className="x__mseries-page__features">
      <ul>
        {data.map(({ alt, description, suptitle }, index) => (
          <li key={index}>
            {suptitle ? <small dangerouslySetInnerHTML={{ __html: suptitle }} /> : null}
            <div>{iconSVG(alt)[index]}</div>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </li>
        ))}
      </ul>
    </section>
  )
}
