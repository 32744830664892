import React from 'react'

export default function IconMsSvg({ alt }) {
  return (
    <svg width={83} height={46} viewBox="0 0 83 46" fill="none">
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0505 0.910782C29.0505 3.18762 28.2103 5.3135 26.5354 7.2812C24.5143 9.6204 22.0696 10.9721 19.4185 10.7588C19.3848 10.4857 19.3652 10.1982 19.3652 9.89608C19.3652 7.71031 20.3263 5.37111 22.0333 3.4585C22.8854 2.49011 23.9692 1.6849 25.2836 1.04258C26.595 0.409838 27.8356 0.0599132 29.0023 0C29.0363 0.304371 29.0505 0.608771 29.0505 0.910754V0.910782ZM37.925 35.4789C37.2295 37.0694 36.4063 38.5336 35.4525 39.8796C34.1524 41.7147 33.0879 42.9849 32.2675 43.6903C30.9957 44.848 29.6332 45.441 28.1741 45.4747C27.1266 45.4747 25.8634 45.1796 24.3929 44.5811C22.9177 43.9853 21.5619 43.6903 20.3222 43.6903C19.0221 43.6903 17.6277 43.9853 16.1363 44.5811C14.6426 45.1796 13.4392 45.4916 12.5192 45.5225C11.12 45.5815 9.72538 44.9717 8.33328 43.6903C7.44477 42.9231 6.33342 41.6079 5.00207 39.7447C3.57364 37.7551 2.39927 35.448 1.47925 32.8176C0.493933 29.9765 0 27.2254 0 24.5619C0 21.5108 0.665958 18.8794 1.99986 16.6742C3.04819 14.903 4.44284 13.5057 6.18836 12.48C7.93387 11.4543 9.81991 10.9316 11.851 10.8982C12.9623 10.8982 14.4197 11.2385 16.2308 11.9073C18.0368 12.5784 19.1964 12.9187 19.7048 12.9187C20.0849 12.9187 21.3731 12.5208 23.5569 11.7275C25.6221 10.9917 27.365 10.6871 28.7929 10.8071C32.662 11.1162 35.5689 12.6262 37.502 15.3464C34.0417 17.422 32.3299 20.3292 32.364 24.0586C32.3952 26.9634 33.4597 29.3808 35.5518 31.3001C36.5 32.191 37.5588 32.8795 38.7369 33.3684C38.4814 34.1019 38.2117 34.8044 37.925 35.4789ZM82.544 11.883V46H76.184V22.9716H75.9949L66.7268 45.669H62.2346L52.9665 22.9716H52.7773V46H46.4173V11.883H54.6924L64.3861 36.0699H64.5752L74.2689 11.883H82.544Z"
        fill="url(#paint0_linear_4532_37713)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4532_37713"
          x1={-3.27631e-7}
          y1={25.2375}
          x2={82.5441}
          y2={25.2375}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
