import React from 'react'

export default function Icon15xSvg({ alt }) {
  return (
    <svg width={93} height={47} viewBox="0 0 93 47" fill="none">
      <title>{alt}</title>
      <path
        d="M19.0938 0.34375V46H11.5625V9.28125L0.40625 13.0625V6.84375L18.1875 0.34375H19.0938ZM35.8125 24.8438L29.8125 23.375L32.2812 0.5H56.8125V6.875H38.5312L37.2812 18.0312C37.9896 17.6146 38.9688 17.2083 40.2188 16.8125C41.4688 16.3958 42.8958 16.1875 44.5 16.1875C46.6458 16.1875 48.5625 16.5417 50.25 17.25C51.9583 17.9375 53.4062 18.9479 54.5938 20.2812C55.7812 21.5938 56.6875 23.1979 57.3125 25.0938C57.9375 26.9688 58.25 29.0833 58.25 31.4375C58.25 33.5417 57.9375 35.5104 57.3125 37.3438C56.7083 39.1771 55.7917 40.7917 54.5625 42.1875C53.3333 43.5833 51.7812 44.6771 49.9062 45.4688C48.0521 46.2396 45.8542 46.625 43.3125 46.625C41.4167 46.625 39.5938 46.3542 37.8438 45.8125C36.1146 45.25 34.5625 44.4271 33.1875 43.3438C31.8125 42.2396 30.7083 40.8854 29.875 39.2812C29.0417 37.6562 28.5625 35.7917 28.4375 33.6875H35.8125C36 35.1667 36.4062 36.4271 37.0312 37.4688C37.6771 38.4896 38.5312 39.2708 39.5938 39.8125C40.6562 40.3542 41.8854 40.625 43.2812 40.625C44.5521 40.625 45.6458 40.4062 46.5625 39.9688C47.4792 39.5104 48.2396 38.8646 48.8438 38.0312C49.4688 37.1771 49.9271 36.1771 50.2188 35.0312C50.5312 33.8854 50.6875 32.6146 50.6875 31.2188C50.6875 29.8854 50.5104 28.6667 50.1562 27.5625C49.8229 26.4583 49.3125 25.5 48.625 24.6875C47.9583 23.875 47.1146 23.25 46.0938 22.8125C45.0729 22.3542 43.8958 22.125 42.5625 22.125C40.7708 22.125 39.3958 22.3854 38.4375 22.9062C37.5 23.4271 36.625 24.0729 35.8125 24.8438ZM67.7812 39.6562L63.2188 35.125L87.5625 10.2812L92.125 14.8438L67.7812 39.6562ZM87.625 39.6562L63.2812 14.8438L67.8438 10.2812L92.1875 35.125L87.625 39.6562Z"
        fill="url(#paint0_linear_4532_37706)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4532_37706"
          x1={-5}
          y1={28.2804}
          x2={95.0001}
          y2={28.2804}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
