import React from 'react'

export default function IconExodusSvg({ alt }) {
  return (
    <svg width={60} height={60} viewBox="0 0 60 60" fill="none">
      <title>{alt}</title>
      <path
        d="M59.6407 16.7529L34.0899 0V9.36664L50.4809 20.0178L48.5526 26.1195H34.0899V33.8805H48.5526L50.4809 39.9822L34.0899 50.6334V60L59.6407 43.3006L55.4625 30.0268L59.6407 16.7529Z"
        fill="url(#paint0_linear_4532_37723)"
      />
      <path
        d="M11.8601 33.8805H26.2693V26.1195H11.8066L9.93179 20.0178L26.2693 9.36664V0L0.718506 16.7529L4.89662 30.0268L0.718506 43.3006L26.3229 60V50.6334L9.93179 39.9822L11.8601 33.8805Z"
        fill="url(#paint1_linear_4532_37723)"
      />
      <mask
        id="mask0_4532_37723"
        style={{ 'mask-type': 'alpha' }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={60}
        height={60}
      >
        <path
          d="M59.6407 16.7529L34.0899 3.05176e-05V9.36666L50.481 20.0179L48.5526 26.1196H34.0899V33.8805H48.5526L50.481 39.9822L34.0899 50.6334V60L59.6407 43.3006L55.4626 30.0268L59.6407 16.7529Z"
          fill="url(#paint2_linear_4532_37723)"
        />
        <path
          d="M11.8602 33.8805H26.2694V26.1196H11.8066L9.93185 20.0179L26.2694 9.36666V3.05176e-05L0.718567 16.7529L4.89668 30.0268L0.718567 43.3006L26.3229 60V50.6334L9.93185 39.9822L11.8602 33.8805Z"
          fill="url(#paint3_linear_4532_37723)"
        />
      </mask>
      <g mask="url(#mask0_4532_37723)">
        <rect
          x={0.750061}
          y={3.05176e-5}
          width={58.5}
          height={60}
          fill="url(#paint4_linear_4532_37723)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4532_37723"
          x1={0.718506}
          y1={32.9185}
          x2={59.6407}
          y2={32.9185}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4532_37723"
          x1={0.718506}
          y1={32.9185}
          x2={59.6407}
          y2={32.9185}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4532_37723"
          x1={0.718567}
          y1={32.9185}
          x2={59.6408}
          y2={32.9185}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4532_37723"
          x1={0.718567}
          y1={32.9185}
          x2={59.6408}
          y2={32.9185}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4532_37723"
          x1={0.750061}
          y1={32.9185}
          x2={59.2501}
          y2={32.9185}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5C5E69" />
          <stop offset={1} stopColor="#E9E9E9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
