import { graphql, useStaticQuery } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

import { useRedditPixel, useMetaPixel, useTikTokPixel } from 'components/head/Analytics'
import SimpleHead from 'components/head/SimpleHead'
import { Header, Footer } from 'components/layout'
import { FeaturesSection, HeaderSection } from 'components/pages/m-series'
import pageData from 'data/pages/Contents/m-series.json'

import 'static/m-series/scss/styles.scss'

export default function MseriesPage() {
  const { header, features } = pageData.content

  const imagesQuery = useStaticQuery(graphql`
    query {
      bgMobile: file(relativePath: { regex: "/^m-series\/img\/desktop-app-m\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 762, placeholder: BLURRED)
        }
      }
      bgTablet: file(relativePath: { regex: "/^m-series\/img\/desktop-app-t\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1524, placeholder: BLURRED)
        }
      }
      bgDesktop: file(relativePath: { regex: "/^m-series\/img\/desktop-app-d\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 2284, placeholder: BLURRED)
        }
      }
    }
  `)

  const previewImage = withArtDirection(getImage(imagesQuery.bgDesktop), [
    { media: '(max-width: 1280px)', image: getImage(imagesQuery.bgTablet) },
    { media: '(max-width: 768px)', image: getImage(imagesQuery.bgMobile) },
  ])

  useRedditPixel()
  useMetaPixel()
  useTikTokPixel()

  return (
    <div className="x__mseries-page">
      <Header background="black" />
      <main className="x">
        {header && <HeaderSection data={{ ...header, image: previewImage }} />}
        {features && <FeaturesSection data={features} />}
      </main>
      <Footer black />
    </div>
  )
}

export const Head = () => {
  const { title, description } = pageData

  return <SimpleHead title={title} description={description} />
}
