import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import Button from 'components/button'
import logEvent from 'js/analytics'
import { DOWNLOAD_MACOS_MS_PAGE } from 'js/analytics/trackers'
import processCampaigns from 'js/campaigns'
import useDimensions from 'js/utils/hooks/useDimensions'
import useIsMounted from 'js/utils/hooks/useIsMounted'
import { useLatestVersion } from 'js/utils/hooks/useLatestVersion'
import useRellax from 'js/utils/hooks/useRellax'
import { DOWNLOAD_PAGE_PATH, DOWNLOADS_URL, PRIVACY_URL, TERMS_URL } from 'src/constants'

import msChipIcon from 'static/m-series/img/apple-M1-chip.svg'

import 'components/pages/m-series/scss/header-section.scss'

export default function HeaderSection({ data }) {
  const { h1, h2, image, alt } = data

  const { isMobile } = useDimensions()
  const isMounted = useIsMounted()

  useRellax({ className: 'mseries-page-rellax', center: false })

  const handleDownloadLinkClick = () => {
    processCampaigns({ eventType: 'download' })
    logEvent(DOWNLOAD_MACOS_MS_PAGE(), 'MsPage')
    rdt('track', 'AddToCart') // eslint-disable-line no-undef
  }

  const releaseVersion = useLatestVersion().latest
  const buildDownloadLink = `${DOWNLOADS_URL}/exodus-macos-arm64-${releaseVersion}.dmg`

  return (
    <header className="x__mseries-page__header">
      <div className="x__mseries-page__header__content">
        <div className="x__mseries-page__header__icon mseries-page-rellax" data-rellax-speed={-4}>
          <img src={msChipIcon} alt="M Series icon" />
        </div>

        <div
          className="x__mseries-page__header__content-wrapper mseries-page-rellax"
          data-rellax-speed={-2}
        >
          {h1 && <h1 className="x__mseries-page__header__heading">{h1}</h1>}
          {h2 && <h2 className="x__mseries-page__header__subheading">{h2}</h2>}

          {isMounted ? (
            <div className="x__mseries-page__header__actions">
              <Button
                copy={isMobile ? 'Download Exodus' : 'Download Exodus for Mac'}
                to={isMobile ? DOWNLOAD_PAGE_PATH : buildDownloadLink}
                onClick={handleDownloadLinkClick}
                size="largest"
              />
              <p>
                {isMobile ? null : (
                  <>
                    By downloading Exodus, you agree to the{' '}
                    <Link href={TERMS_URL} target="_blank">
                      Terms of Use
                    </Link>{' '}
                    and{' '}
                    <Link href={PRIVACY_URL} target="_blank">
                      Privacy Policy
                    </Link>
                  </>
                )}
              </p>
            </div>
          ) : null}
        </div>
      </div>

      {image && (
        <div
          className="x__mseries-page__header__preview mseries-page-rellax"
          data-rellax-speed={-5}
        >
          <GatsbyImage image={image} alt={alt || 'preview'} loading="eager" />
        </div>
      )}
    </header>
  )
}
